"use strict";
const showMoreButton = document.querySelector('#infoServices__showMoreButton');
const showLessButton = document.querySelector('#infoServices__showLessButton');
const restServices = document.querySelector('#infoServices__restServices');
showMoreButton.addEventListener('click', () => {
    showMoreButton.classList.replace('flex', 'hidden');
    showLessButton.classList.replace('hidden', 'flex');
    restServices.classList.replace('hidden', 'grid');
});
showLessButton.addEventListener('click', () => {
    showMoreButton.classList.replace('hidden', 'flex');
    showLessButton.classList.replace('flex', 'hidden');
    restServices.classList.replace('grid', 'hidden');
});
